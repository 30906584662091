import { Box, Text } from "@chakra-ui/react";
// import { FooterBox } from "../../Views/Home/FooterBox";
// import { HeaderBox } from "../../Views/Home/HeaderBox";
// import { ContentBox } from "../../Views/Home/ContentBox";

const HomeView = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" h="100vh">
      <Text color="white">
        website currently under construction
      </Text>
      <Text color="white">
        last update - 1 Feb 2025
      </Text>
      {/* <HeaderBox />
      <ContentBox />
      <FooterBox /> */}
    </Box>
  );
};

export default HomeView;
